<template>
  <div class="form">
    <div
      v-for="(family, i) in families" :key="i"
      class="family"
    >
      <div class="family-head row">
        <Input
          title="Nom de la famille d'exercices"
          type="text"
          v-model="family.name"
        />

        <Button
          title="Ajouter un exercice"
          type="secondary"
          @click="openAvailableExercisesModal(family)"
        />

        <Button
          title="Supprimer la famille"
          type="warning"
          @click="removeFamily(family)"
        />
      </div>

      <transition-group tag="div" name="list" class="exercises grid">
        <div
          v-for="(exercise) in family.exercises" :key="exercise"
          class="exercise column"
        >
          <Thumbnail
            :title="getExercise(exercise).name"
            :imageUrl="getExercise(exercise).thumbnailUrl"
          />

          <Button
            title="Supprimer"
            type="warning"
            @click="removeExercise(exercise, family.exercises)"
          />
        </div>
      </transition-group>
    </div>

    <div class="row buttons-container">
      <Button
        title="Ajouter une famille d'exercices"
        type="secondary"
        @click="addFamily()"
      />
    </div>

    <BottomBar>
      <div class="message">
        <p v-if="hasFamiliesChanged">
          Modifications non sauvegardées
        </p>
      </div>

      <Button
        title="Sauvegarder les modifications"
        type="primary"
      />
    </BottomBar>

    <Modal
      class="modal"
      v-if="displayAvailableExercisesModal"
      :close="closeAvailableExercisesModal">

      <h2 class="title-2">Sélectionner un exercice</h2>

      <div class="grid" v-if="availableExercises.length > 0">
        <div
          v-for="(exercise, i) in availableExercises" :key="i"
          class="exercise column"
          @click="addExercise(exercise.id, selectedFamily)"
        >
          <Thumbnail
            class="thumbnail"
            :title="exercise.name"
            :imageUrl="exercise.thumbnailUrl"
          />
        </div>
      </div>
      <div v-else class="no-exercise">
        Tous les exercices ont déjà été assignés à une famille.
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Input from '@/components/Forms/Fields/Input'
import Thumbnail from '@/components/Thumbnail'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import BottomBar from '@/components/Layout/BottomBar'

export default {
  name: 'ExercisesFamiliesForm',
  components: {
    Thumbnail, Input, Button, Modal, BottomBar
  },
  props: ['families'],
  data () {
    return {
      originalFamilies: {},
      displayAvailableExercisesModal: false,
      selectedFamily: null
    }
  },
  async created () {
    this.originalFamilies = _.cloneDeep(this.families)
    await this.$store.dispatch('admin/getExercises')
  },
  methods: {
    getExercise (id) {
      return this.exercises.find(exercise => exercise.id === id) ?? { name: '' }
    },
    removeExercise (id, family) {
      family.splice(family.indexOf(id), 1)
    },
    openAvailableExercisesModal (family) {
      this.selectedFamily = family
      this.displayAvailableExercisesModal = true
    },
    closeAvailableExercisesModal () {
      this.selectedFamily = null
      this.displayAvailableExercisesModal = false
    },
    addExercise (id, family) {
      family.exercises.push(id)
      this.displayAvailableExercisesModal = false
    },
    addFamily () {
      this.families.push({
        name: '',
        exercises: []
      })
    },
    removeFamily (family) {
      this.families.splice(this.families.indexOf(family), 1)
    }
  },
  computed: {
    ...mapGetters({
      exercises: 'admin/exercises'
    }),
    availableExercises () {
      const unavailableExercises = this.families.reduce((acc, family) => [...acc, ...family.exercises], [])
      return this.exercises.filter(exercise => !unavailableExercises.includes(exercise.id))
    },
    hasFamiliesChanged () {
      return !_.isEqual(this.originalFamilies, this.families)
    }
  }
}
</script>

<style lang="scss" scoped>
.family{
  border-bottom: 5px solid $light-grey-color;
  padding-bottom: 20px;
  margin-bottom: 20px;

  .family-head{
    align-items: flex-start;
    & > div:first-child{
      flex-grow: 1;
    }
  }
}

.grid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin: 10px;

  .exercise{
    margin-bottom: 10px;

    .button{
      margin-right: 10px;
    }
  }
}

.buttons-container{
  justify-content: center;
  margin: 20px 0;

  div {
    flex: 0;
  }
}

.modal{
  .thumbnail{
    cursor: pointer;
  }

  .no-exercise{
    margin-bottom: 40px;
  }
}

.message{
  flex-grow: 1;
}

.list-enter-active, .list-leave-active {
  transition: all 0.25s;
}
.list-enter, .list-leave-to{
  opacity: 0;
}
</style>
