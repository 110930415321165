<template>
  <div class="modal-wrapper" @click.self="close">
    <div class="modal-content" :class="addScroll ? 'scroll-vertical' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["close", "addScroll"]
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content {
    background: $white-color;
    border-radius: $border-radius;
    min-width: 50vw;
    min-height: 50vh;
    max-width: 50vw;
    max-height: 90vh;
    padding: 15px;
  }
  .scroll-vertical {
    overflow-y: auto;
  }
}
</style>
