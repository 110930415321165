<template>
  <Card class="adminNav">
    <p class="title">Base de données</p>

    <ul>
      <li v-for="(page, i) in navigations" :key="i">
        <router-link :to="{ name: page.viewName }">{{ page.name }}</router-link>
      </li>
    </ul>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '@/components/Layout/Card'

export default {
  name: 'AdminNav',
  components: {
    Card
  },
  computed: {
    ...mapGetters({
      navigations: 'navDatabase'
    })
  }
}
</script>

<style lang="scss" scoped>
.adminNav{
  .title{
    font-size: 1.25rem;
    font-weight: 420;
    margin-bottom: 20px;
  }

  ul{
    li{
      font-size: 1.1rem;
      padding: 0.3rem 0;
      transition: all 0.25s;

      &:hover{
        color: $main-color;
      }

      .router-link-active{
        color: $main-color;
      }
    }
  }
}
</style>
