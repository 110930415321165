<template>
  <Page>
    <Content class="page-content">
      <AdminNav class="nav" />
      <Card class="content-wrapper">
        <slot />
      </Card>
    </Content>
  </Page>
</template>

<script>
import Page from '@/components/Layout/Page'
import Content from '@/components/Layout/Content'
import Card from '@/components/Layout/Card'
import AdminNav from '@/components/AdminNav'

export default {
  name: 'AdminView',
  components: {
    Page, Content, Card, AdminNav
  }
}
</script>

<style lang="scss" scoped>
.page-content{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .nav{
    min-width: 300px;
    margin-right: 10px;
    position: sticky;
    top: 24px;
  }

  .content-wrapper{
    flex-grow: 1;
    margin-left: 10px;
    margin-bottom: 60px;
  }
}
</style>
