<template>
  <div class="bottomBar">
    <Content class="content">
      <div class="bottomBar-content">
        <slot />
      </div>
    </Content>
  </div>
</template>

<script>
import Content from '@/components/Layout/Content'

export default {
  name: 'BottomBar',
  components: {
    Content
  }
}
</script>

<style lang="scss" scoped>
.bottomBar{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 75px;
  background: $white-color;
  box-shadow: $box-shadow;

  .content{
    height: 100%;

    .bottomBar-content{
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
