<template>
  <div class="button" :class="[type, size, { disabled, active }]" @click="onClick">
    <div class="button-content">
      <div class="icon" v-if="$slots.default">
        <slot />
      </div>
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    title: String,
    type: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "large"
    },
    SVGIcon: {
      type: [String, Object],
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick($event) {
      if (!this.disabled) {
        this.$emit("click", $event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  flex-grow: 0;
  background-color: $white-color;
  border-radius: $border-radius;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 12px 24px;
  cursor: pointer;
  transition: all 0.25s;
  white-space: nowrap;
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  .button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 15px;
      margin-right: 10px;
    }
  }

  &.primary {
    background-color: $main-color;
    color: $white-color;
    border: 1px solid $main-color;

    path {
      fill: $white-color;
    }

    &:hover,
    &.active {
      color: $main-color;
      background-color: $white-color;

      path {
        fill: $main-color;
      }
    }
  }

  &.secondary {
    background-color: $secondary-color;
    color: $white-color;
    border: 1px solid $secondary-color;

    path {
      fill: $white-color;
    }

    &:hover,
    &.active {
      color: $secondary-color;
      background-color: $white-color;

      path {
        fill: $secondary-color;
      }
    }
  }

  &.warning {
    background-color: $warning-color;
    color: $white-color;
    border: 1px solid $warning-color;

    path {
      fill: $white-color;
    }

    &:hover,
    &.active {
      color: $warning-color;
      background-color: $white-color;

      path {
        fill: $warning-color;
      }
    }
  }
  &.delete {
    background-color: $light-text-color;
    color: $white-color;
    &:hover,
    &.active {
      color: $light-text-color;
      background-color: $white-color;
      border: 1px solid $light-text-color;

      path {
        fill: $light-text-color;
      }
    }
  }
  &.not-allowed {
    background-color: #8e8e95;
    color: $white-color;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.small {
    font-size: 1.1rem;
    padding: 4px 12px;
    margin: 0 5px;
  }
}
</style>
