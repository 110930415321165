<template>
  <AdminView>
    <h1 class="title-1">Familles d'exercices</h1>

    <ExercisesFamiliesForm
      v-if="families.length > 0"
      :families="families"
    />
  </AdminView>
</template>

<script>
import AdminView from '@/components/AdminView'
import ExercisesFamiliesForm from '@/components/Forms/ExercisesFamiliesForm'

export default {
  name: 'ExercisesFamilies',
  components: {
    AdminView, ExercisesFamiliesForm
  },
  data () {
    return {
      families: [
        {
          name: 'Famille 1',
          exercises: ['ce36c35b-0632-4e0c-bcd3-089516c8b5aa']
        },
        {
          name: 'Famille 2',
          exercises: ['366829c8-6e03-4817-827a-095ed2fdd3ba']
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
