<template>
  <div class="thumbnail">
    <div class="image">
      <img v-if="imageUrl" :src="imageUrl" alt="imageurl" />
    </div>

    <p class="title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    title: String,
    imageUrl: String
  }
}
</script>

<style lang="scss" scoped>
.thumbnail{
  width: 100%;
  margin-bottom: 20px;
  transition: all 0.25s;

  .image{
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    padding-top: 56.25%;
    border-radius: 10px;
    border: 1px solid $white-color;
    background: $light-grey-color;
    transition: all 0.25s;

    img{
      position: absolute;
      top: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .title{
    font-size: 1.25rem;
    font-weight: 420;
  }

  &:hover{
    color: $main-color;

    .image{
      border: 1px solid $main-color;
    }
  }
}
</style>
