<template>
  <div class="field" :class="containerClass">
    <label v-if="title" :for="name" :class="labelClass">{{ title }}</label>
    <input
      v-if="type === 'file'"
      :type="type"
      :id="name"
      @input="$emit('input', $event.target.files)"
      @blur="$emit('blur')"
      ref="input"
    />
    <textarea
      v-else-if="type === 'textarea'"
      :type="type"
      :id="name"
      :class="inputClassName"
      :value="valueChanged"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
      ref="input"
      :disabled="isDisabled"
    ></textarea>
    <input
      v-else
      :type="type"
      :id="name"
      :class="inputClassName"
      :value="valueChanged"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
      ref="input"
      :disabled="isDisabled"
    />
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    inputClassName: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      default: ""
    },
    containerClass: {
      type: String,
      default: ""
    },
    title: String,
    type: {
      type: String,
      default: "text"
    },
    name: String,
    value: [String, Number, FileList, Array],
    isDisabled: {
      type: Boolean,
      default: false
    },
    initialValue: {
      default: ""
    }
  },
  data() {
    return {
      valueChanged: ""
    }
  },
  created() {
    if (this.initialValue) {
      this.valueChanged = this.initialValue
    } else {
      this.valueChanged = this.value
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;

  label {
    font-size: 1rem;
    font-weight: 420;
    margin-bottom: 10px;
  }

  input,
  textarea {
    padding: 10px 10px;
    font-size: 1.05rem;
    background: $light-background-color;
    -webkit-appearance: none;
    border: 1px solid $light-background-color;
    border-radius: 10px;
    transition: all 0.25s;

    &:focus {
      outline: none;
      border: 1px solid $light-text-color;
    }

    &:hover {
      border: 1px solid $light-text-color;
    }
  }
  .heigh-comment {
    height: 156px;
  }
}
.border-red {
  border: red 1px solid !important;
}
</style>
